const BALANCE_GRAPH_DATA = [
    ['Fri Apr 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 2681.17],
    [
        'Thu Apr 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        845.1980000000001,
    ],
    ['Wed Apr 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 626.279],
    ['Tue Apr 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 517.479],
    ['Mon Apr 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 41.9934],
    ['Sun Apr 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 306.279],
    ['Sat Apr 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 267.215],
    ['Fri Apr 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 238.412],
    ['Thu Apr 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 401.61],
    ['Wed Apr 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 205.642],
    ['Tue Apr 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 81.3956],
    ['Mon Apr 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 232.257],
    [
        'Sun Apr 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        118.32499999999999,
    ],
    [
        'Sat Apr 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        200.85000000000002,
    ],
    [
        'Fri Apr 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        363.10299999999995,
    ],
    ['Thu Apr 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 158.105],
    ['Wed Apr 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 210.82],
    ['Tue Apr 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 101.453],
    [
        'Mon Apr 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        334.27500000000003,
    ],
    ['Sun Apr 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 587.933],
    ['Sat Apr 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 615.762],
    [
        'Fri Apr 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        343.69399999999996,
    ],
    [
        'Thu Apr 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        303.30400000000003,
    ],
    ['Wed Apr 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 382.216],
    ['Tue Apr 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 334.074],
    ['Mon Apr 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 30.3673],
    [
        'Sun Mar 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        223.39200000000002,
    ],
    ['Sat Mar 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 70.6639],
    ['Fri Mar 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1091.02],
    ['Thu Mar 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1021.28],
    ['Wed Mar 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 877.291],
    ['Tue Mar 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1077.22],
    ['Mon Mar 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 906.307],
    [
        'Sun Mar 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        464.70799999999997,
    ],
    ['Sat Mar 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 227.227],
    ['Fri Mar 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 415.356],
    ['Thu Mar 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 440.596],
    ['Wed Mar 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 239.362],
    [
        'Tue Mar 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        1294.0700000000002,
    ],
    ['Mon Mar 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 537.95],
    ['Sun Mar 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1364.28],
    ['Sat Mar 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 605.911],
    ['Fri Mar 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 634.01],
    [
        'Thu Mar 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        929.1039999999999,
    ],
    ['Wed Mar 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 763.539],
    ['Tue Mar 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 611.86],
    ['Mon Mar 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 699.293],
    ['Sun Mar 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 986.944],
    ['Sat Mar 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1033.77],
    ['Fri Mar 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 567.206],
    ['Thu Mar 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 713.96],
    ['Wed Mar 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 747.131],
    ['Tue Mar 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 783.26],
    ['Mon Mar 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 684.058],
    ['Sun Mar 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 671.568],
    ['Sat Mar 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 689.556],
    ['Fri Mar 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 712.353],
    ['Thu Feb 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 644.859],
    [
        'Wed Feb 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        498.84000000000003,
    ],
    ['Tue Feb 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 702.093],
    ['Mon Feb 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 690.039],
    [
        'Sun Feb 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        700.1659999999999,
    ],
    ['Sat Feb 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 675],
    [
        'Fri Feb 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        810.8119999999999,
    ],
    [
        'Thu Feb 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        874.5310000000001,
    ],
    ['Wed Feb 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1041.52],
    ['Tue Feb 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1461.27],
    ['Mon Feb 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1739.81],
    [
        'Sun Feb 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        1671.8100000000002,
    ],
    ['Sat Feb 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1427.97],
    [
        'Fri Feb 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        456.52099999999996,
    ],
    ['Thu Feb 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 647.955],
    ['Wed Feb 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 847.963],
    ['Tue Feb 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 650.731],
    ['Mon Feb 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 867.085],
    ['Sun Feb 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 455.597],
    ['Sat Feb 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 709.436],
    [
        'Fri Feb 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        519.9929999999999,
    ],
    ['Thu Feb 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 791.075],
    ['Wed Feb 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 658.288],
    ['Tue Feb 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 719.02],
    ['Mon Feb 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 799.388],
    ['Sun Feb 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 996.502],
    ['Sat Feb 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 879.74],
    ['Fri Feb 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 698.76],
    ['Thu Feb 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 755.452],
    ['Wed Jan 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1008.65],
    ['Tue Jan 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 498.843],
    ['Mon Jan 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 500.565],
    ['Sun Jan 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 953.693],
    ['Sat Jan 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 845.78],
    ['Fri Jan 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 895.188],
    ['Thu Jan 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1355.65],
    ['Wed Jan 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1088.45],
    ['Tue Jan 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1056.21],
    ['Mon Jan 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 920.67],
    ['Sun Jan 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 632.237],
    ['Sat Jan 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 518.625],
    ['Fri Jan 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 27.7339],
    ['Thu Jan 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 125.352],
    ['Wed Jan 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 121.764],
    [
        'Tue Jan 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        377.91499999999996,
    ],
    ['Mon Jan 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 79.4196],
    ['Sun Jan 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 33.5181],
    [
        'Sat Jan 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        119.02699999999999,
    ],
    ['Fri Jan 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 52.9721],
    ['Thu Jan 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 28.6754],
    [
        'Wed Jan 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        38.285399999999996,
    ],
    ['Tue Jan 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 283.433],
    ['Mon Jan 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 728.125],
    [
        'Sun Jan 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        847.1389999999999,
    ],
    ['Sat Jan 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1019.81],
    ['Fri Jan 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 846.734],
    ['Thu Jan 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 31.3715],
    ['Wed Jan 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 499.71],
    ['Tue Jan 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 119.327],
    ['Mon Jan 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 482.567],
    ['Sun Dec 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 198.104],
    ['Sat Dec 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 72.0724],
    ['Fri Dec 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 231.756],
    ['Thu Dec 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 42.2478],
    ['Wed Dec 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1503.32],
    ['Tue Dec 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1959.83],
    ['Mon Dec 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1797.16],
    ['Sun Dec 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1793.52],
    ['Sat Dec 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1750.46],
    ['Fri Dec 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1939.72],
    ['Thu Dec 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1936.14],
    ['Wed Dec 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1848.09],
    ['Tue Dec 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2089.96],
    ['Mon Dec 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2005.5],
    ['Sun Dec 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2255.69],
    ['Sat Dec 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2063.37],
    ['Fri Dec 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2113.86],
    ['Thu Dec 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2245.35],
    ['Wed Dec 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2355.11],
    ['Tue Dec 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2349.95],
    ['Mon Dec 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1214.24],
    ['Sun Dec 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1643.84],
    ['Sat Dec 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1359.7],
    ['Fri Dec 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1105.23],
    ['Thu Dec 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1393.62],
    [
        'Wed Dec 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1431.1000000000001,
    ],
    [
        'Tue Dec 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1368.3799999999999,
    ],
    ['Mon Dec 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1388.73],
    ['Sun Dec 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1449.46],
    ['Sat Dec 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1486.68],
    [
        'Fri Dec 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1567.5700000000002,
    ],
    ['Thu Nov 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1485.58],
    ['Wed Nov 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1518.5],
    [
        'Tue Nov 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1381.6100000000001,
    ],
    ['Mon Nov 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.9643],
    [
        'Sun Nov 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        125.81099999999999,
    ],
    ['Sat Nov 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 81.0059],
    ['Fri Nov 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 13.5367],
    [
        'Thu Nov 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        72.69059999999999,
    ],
    ['Wed Nov 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 303.178],
    ['Tue Nov 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 390.664],
    ['Mon Nov 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1084.28],
    ['Sun Nov 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 931.322],
    ['Sat Nov 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 594.237],
    ['Fri Nov 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 737.019],
    [
        'Thu Nov 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        925.3100000000001,
    ],
    ['Wed Nov 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 679.909],
    ['Tue Nov 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 830.89],
    ['Mon Nov 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1032.53],
    ['Sun Nov 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 848.025],
    ['Sat Nov 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 915.045],
    ['Fri Nov 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 953.854],
    [
        'Thu Nov 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        727.5400000000001,
    ],
    ['Wed Nov 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 632.879],
    ['Tue Nov 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 668.111],
    ['Mon Nov 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 600.547],
    ['Sun Nov 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 719.538],
    ['Sat Nov 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 760.646],
    ['Fri Nov 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 729.863],
    ['Thu Nov 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 769.64],
    ['Wed Nov 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 904.243],
    ['Tue Oct 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 879.486],
    ['Mon Oct 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 871.566],
    ['Sun Oct 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 709.869],
    [
        'Sat Oct 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        819.8789999999999,
    ],
    ['Fri Oct 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 961.328],
    ['Thu Oct 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1005.84],
    ['Wed Oct 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 142.278],
    ['Tue Oct 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 421.909],
    ['Mon Oct 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 10.7643],
    ['Sun Oct 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 48.6923],
    [
        'Sat Oct 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        63.957699999999996,
    ],
    ['Fri Oct 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 67.1559],
    ['Thu Oct 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.6793],
    ['Wed Oct 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 131.262],
    ['Tue Oct 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 158.28],
    [
        'Mon Oct 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        14.946299999999999,
    ],
    ['Sun Oct 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 140.206],
    ['Sat Oct 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 19.2818],
    ['Fri Oct 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 19.0823],
    ['Thu Oct 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.6124],
    [
        'Wed Oct 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        73.28020000000001,
    ],
    ['Tue Oct 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.3169],
    ['Mon Oct 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 133.536],
    ['Sun Oct 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 185.592],
    ['Sat Oct 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 128.667],
    ['Fri Oct 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 580.911],
    ['Thu Oct 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 630.928],
    [
        'Wed Oct 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        431.10799999999995,
    ],
    [
        'Tue Oct 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        486.20399999999995,
    ],
    ['Mon Oct 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 480.147],
    ['Sun Oct 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 235.609],
    ['Sat Sep 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 416.319],
    ['Fri Sep 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 108.024],
    ['Thu Sep 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 234.503],
    [
        'Wed Sep 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        365.22799999999995,
    ],
    ['Tue Sep 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 294.788],
    ['Mon Sep 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 559.429],
    ['Sun Sep 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 510.088],
    ['Sat Sep 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 314.245],
    ['Fri Sep 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 324.193],
    ['Thu Sep 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 165.326],
    ['Wed Sep 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 279.83],
    [
        'Tue Sep 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        174.09099999999998,
    ],
    ['Mon Sep 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 160.901],
    ['Sun Sep 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 275.17],
    ['Sat Sep 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 295.919],
    [
        'Fri Sep 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        244.89100000000002,
    ],
    ['Thu Sep 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 231.192],
    ['Wed Sep 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 189.947],
    ['Tue Sep 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 316.401],
    ['Mon Sep 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 198.671],
    ['Sun Sep 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 108.436],
    ['Sat Sep 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 274.097],
    ['Fri Sep 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 428.648],
    ['Thu Sep 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 559.977],
    ['Wed Sep 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 521.889],
    ['Tue Sep 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 639.728],
    [
        'Mon Sep 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        497.76000000000005,
    ],
    [
        'Sun Sep 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        574.0509999999999,
    ],
    ['Sat Sep 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 661.904],
    ['Fri Sep 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 413.212],
    ['Thu Aug 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 420.614],
    [
        'Wed Aug 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        298.99899999999997,
    ],
    ['Tue Aug 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 248.293],
    [
        'Mon Aug 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        245.04500000000002,
    ],
    ['Sun Aug 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 142.002],
    ['Sat Aug 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 230.841],
    ['Fri Aug 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 234.179],
    [
        'Thu Aug 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        216.53500000000003,
    ],
    ['Wed Aug 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 356.361],
    [
        'Tue Aug 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        400.48499999999996,
    ],
    ['Mon Aug 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 276.51],
    [
        'Sun Aug 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        271.08299999999997,
    ],
    [
        'Sat Aug 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        211.51299999999998,
    ],
    ['Fri Aug 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 227.056],
    ['Thu Aug 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 277.734],
    [
        'Wed Aug 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        270.46299999999997,
    ],
    ['Tue Aug 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 450.039],
    ['Mon Aug 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 348.964],
    ['Sun Aug 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 519.723],
    ['Sat Aug 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 248.879],
    ['Fri Aug 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 249.243],
    ['Thu Aug 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 12.6102],
    ['Wed Aug 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 298.188],
    [
        'Tue Aug 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        197.55400000000003,
    ],
    ['Mon Aug 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 341.74],
    [
        'Sun Aug 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        255.79999999999998,
    ],
    [
        'Sat Aug 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        59.900099999999995,
    ],
    ['Fri Aug 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.237],
    [
        'Thu Aug 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        150.46200000000002,
    ],
    ['Wed Aug 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.8635],
    ['Tue Aug 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 74.333],
    [
        'Mon Jul 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        165.22500000000002,
    ],
    [
        'Sun Jul 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        32.360099999999996,
    ],
    ['Sat Jul 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 69.1924],
    ['Fri Jul 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 92.5563],
    [
        'Thu Jul 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        121.31500000000001,
    ],
    ['Wed Jul 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 36.0657],
    [
        'Tue Jul 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        13.462499999999999,
    ],
    ['Mon Jul 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 111.583],
    ['Sun Jul 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 98.4885],
    ['Sat Jul 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 78.5771],
    ['Fri Jul 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 202.029],
    ['Thu Jul 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 417.209],
    [
        'Wed Jul 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        555.4639999999999,
    ],
    ['Tue Jul 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 414.34],
    ['Mon Jul 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 382.375],
    ['Sun Jul 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 477.923],
    [
        'Sat Jul 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        847.7769999999999,
    ],
    ['Fri Jul 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 370.322],
    ['Thu Jul 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 398.995],
    ['Wed Jul 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 310.157],
    ['Tue Jul 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 403.193],
    ['Mon Jul 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 399.4],
    [
        'Sun Jul 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        591.4390000000001,
    ],
    ['Sat Jul 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 566.322],
    ['Fri Jul 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 453.357],
    ['Thu Jul 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 314.725],
    ['Wed Jul 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 581.48],
    ['Tue Jul 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 833.451],
    ['Mon Jul 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 606.187],
    ['Sun Jul 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 603.207],
    [
        'Sat Jul 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        777.6909999999999,
    ],
    ['Fri Jun 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 740.141],
    [
        'Thu Jun 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        871.9799999999999,
    ],
    [
        'Wed Jun 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1171.8899999999999,
    ],
    ['Tue Jun 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 891.769],
    ['Mon Jun 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 815.681],
    ['Sun Jun 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1015.07],
    [
        'Sat Jun 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        880.7299999999999,
    ],
    ['Fri Jun 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 802.442],
    ['Thu Jun 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 859.902],
    ['Wed Jun 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 247.535],
    [
        'Tue Jun 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        247.29399999999998,
    ],
    ['Mon Jun 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 330.712],
    [
        'Sun Jun 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        381.86100000000005,
    ],
    [
        'Sat Jun 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        282.50800000000004,
    ],
    ['Fri Jun 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 380.12],
    ['Thu Jun 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 829.865],
    [
        'Wed Jun 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        795.8889999999999,
    ],
    ['Tue Jun 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 830.317],
    [
        'Mon Jun 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        827.9250000000001,
    ],
    ['Sun Jun 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1246.07],
    ['Sat Jun 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1197.44],
    ['Fri Jun 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 927.559],
    ['Thu Jun 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1091.88],
    ['Wed Jun 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 561.033],
    ['Tue Jun 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 493.547],
    [
        'Mon Jun 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        487.64000000000004,
    ],
    ['Sun Jun 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 122.532],
    ['Sat Jun 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 639.563],
    [
        'Fri Jun 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        675.8159999999999,
    ],
    ['Thu Jun 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 542.053],
    ['Wed May 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 630.331],
    [
        'Tue May 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        676.0680000000001,
    ],
    [
        'Mon May 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        960.0360000000001,
    ],
];
export { 
// eslint-disable-next-line import/prefer-default-export
BALANCE_GRAPH_DATA, };
