import { BalanceGraphPeriod } from './constants';
const mapBalanceGraphData = (data) => data.map((elem) => ([new Date(elem[0]), elem[1]]));
const getDefaultGraphRange = (period) => {
    const currentDate = new Date(Date.now());
    let firstDate;
    switch (period) {
        case BalanceGraphPeriod['30D']:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 30));
            break;
        case BalanceGraphPeriod['90D']:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 90));
            break;
        case BalanceGraphPeriod['180D']:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 180));
            break;
        case BalanceGraphPeriod['1Year']:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 365));
            break;
        default:
            firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 7));
            break;
    }
    return [[currentDate, 0], [firstDate, 0]];
};
export { mapBalanceGraphData, getDefaultGraphRange };
